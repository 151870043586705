<template>
  <b-container class="bg-light page-wrapper sandbox-period-selector-example">
    <div class="main-content-wrapper">
      <b-row no-gutters>
        <b-col> </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <h1 class="text-left mb-0">Column toggler DEMO</h1>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col cols="12">---</b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <column-toggler :columns="columnOptions" @toggle="onToggle" />
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import ColumnToggler from '@/components/common/ColumnToggler'

export default {
  name: 'ColumnTogglerExample',
  components: { ColumnToggler },
  data () {
    return {
      columnOptions: {
        total: { label: 'common.total', isActive: true },
        min: { label: 'common.min', isActive: false },
        max: { label: 'common.max', isActive: true },
        actions: { label: 'common.actions', isActive: false }
      }
    }
  },
  methods: {
    onToggle (data) {
      this.columnOptions = data.columns
    }
  }
}
</script>
