<template>
  <div class="component-column-toggler">
    <b-dropdown right
      :text="$t(caption)"
      variant='outline-secondary'
      :class="dropdownCssClasses"
      size="sm"
    >
      <b-dropdown-item
        v-for="col, index in columns"
        :key="index"
        :class="{ active: col.isActive }"
        @click="toggle(index)"
      >
        {{ $t(col.label) }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'ColumnToggler',
  props: {
    caption: {
      type: String,
      default: 'common.toggle_columns'
    },
    columns: {
      type: Object,
      default: () => { return {} }
    },
    meta: {
      type: Object,
      default: () => { return {} }
    },
    dropdownCssClasses: {
      type: Object,
      default: () => { return { 'float-right': true, 'text-regular': true, 'mr-3': true } }
    }
  },
  methods: {
    toggle (index) {
      const columns = _.cloneDeep(this.columns)
      columns[index].isActive = !columns[index].isActive
      this.$emit('toggle', { columns: columns, meta: this.meta })
    }
  }
}
</script>

<style lang="scss">
.component-column-toggler {
  .b-dropdown {
    ul {
      li {
        font-size: 90%;
        a {
          color: #000;
        }
        &.active {
          a {
            font-weight: 700;
            &::after {
              content: " ✓";
            }
          }
        }
      }
    }
  }
}
</style>
